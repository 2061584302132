import React from 'react';
import Grid from '@material-ui/core/Grid'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Card, CardMedia, Divider, Typography } from '@material-ui/core';
import Learningearning from '../../assets/images/Learningearning.jpg';
import LinksComponent from './LinksComponent';
import RezoomexLogo from './RezoomexLogo';
import Footer from './Footer';
import MenuComponent from './MenuComponent';
import { Link } from 'react-router-dom';

const styles = theme => ({
    leftRightContainer: {
        margin: "0px !important",
        display: "grid",
        justifyContent: "center"
    },
    card: {
        width: "500px",
        border: "none !important",
        boxShadow: "none !important",
        borderRadius: "0px !important",
        [theme.breakpoints.only('xs')]: {
            padding: "0px 20px !important",
            margin: "10px"
        },
    },
    gridItem: {
        display: "flex !important",
        justifyContent: "center !important",
        [theme.breakpoints.only('xs')]: {
            width: "300px !important",
        },
    },
    bgImageContainer: {
        position: "relative"
    },
    bgImg: {
        height: "auto",
        width: "100%",
        border: "none !important",
        boxShadow: "none !important",
        borderRadius: "0px !important",
    },
    bgContentText: {
        position: "absolute",
        color: "white",
        top: "82%",
        left: "50%",
        transform: "translateX(-50%)",
        fontSize: "4.1vw",
        width: "100%",
        fontFamily: "Proxima Nova",
        fontWeight: "Bold",
        [theme.breakpoints.up('lg')]: {
            fontSize: "49px !important",
        },
    },
    logoRezoomex: {
        position: "absolute",
        color: "white",
        top: "2%",
        left: "42%",
        width: "80%",
        transform: "translateX(-50%)",
        fontSize: "4.5vw",
        height: "auto",
        [theme.breakpoints.only('xs')]: {
            marginTop: "-50px !important"
        },
    },
    root: {
        borderRadius: "0 !important",
        boxShadow: "0 !important",
        border: "none !important"
    },
    pageHeader: {
        height: "50px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        backgroundColor: "white",
        [theme.breakpoints.up('sm')]: {
            display: "none !important"
        },
    },
    mainGridItem: {
        display: 'flex',
    },
    gridTextContainer: {
        width: "100%",
        display: 'grid',
        justifyContent: "center"
    },
    LinksComponentClass: {
        [theme.breakpoints.only('xs')]: {
            display: "none !important"
        },
    },
    buttonCardContainer: {
        [theme.breakpoints.only('xs')]: {
            marginTop: "10px"
        },
    },
    pageContent: {
        margin: "40px 40px !important",
        [theme.breakpoints.only('xs')]: {
            margin: "10px 10px !important",
        },
    },
    pageParaText1: {
        textAlign: "left",
        padding: '10px',
        marginBottom: "10px",
        [theme.breakpoints.only('xs')]: {
            fontSize: "0.75rem",
            marginBottom: "5px",
        },
    },
    pageParaText2: {
        textAlign: "left",
        fontSize:"1rem",
        padding: '10px 10px 0px 10px',
        margin: "0px 40px",
        [theme.breakpoints.only('xs')]: {
            fontSize: "0.75rem",
            margin: "0px 25px",
        },
    },
    pageParaText3: {
        textAlign: "left",
        padding: '10px',
        marginBottom: "10px",
        textIndent: "40px",
        [theme.breakpoints.only('xs')]: {
            fontSize: "0.75rem",
            marginBottom: "5px",
            textIndent: "15px",
        },
    }
})

const LearningEarning = (props) => {

    const { classes } = props;

    return (
        <div >
            <div>
                <div>
                    <Grid container>
                        <Grid container sx={{ position: 'relative' }}>
                            <Grid item lg={12} sm={12} md={12} xs={12} >
                                <Card className={classes.root}>
                                    <div className={classes.pageHeader}>
                                        <MenuComponent />
                                    </div>
                                    <div className={classes.bgImageContainer}>
                                        <CardMedia className={classes.bgImg} component="img" image={Learningearning} alt="Learning Earning" />
                                        <div className={classes.bgContentText}>
                                            Intresting side hustle = Learning + Earning
                                        </div>
                                        <div className={classes.logoRezoomex}>
                                            <RezoomexLogo />
                                        </div>
                                    </div>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <div className={classes.LinksComponentClass}>
                <Grid container >
                    <Grid container>
                        <Grid item lg={12} sm={12} md={12} xs={12}>
                            <LinksComponent />
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <div className={classes.leftRightContainer} >
                <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <div className={classes.pageContent}>
                            <div className={classes.pageContent}>
                                <Typography className={classes.pageParaText1}>Companies and employees can transparently engage in smart contracts to create additional value for both. It’s a win-win situation. Rezoomex NFT marketplace has its own well curated list of interesting projects. These projects are preauthorized by the employers. Projects are classified in the following categories -</Typography>
                                <div className={classes.pageParaText2}>
                                    <ul>
                                        <li>2-minute tasks like re-posting messages on social media</li>
                                        <li>Short tasks like conducting an interview or attending a call</li>
                                        <li>Referral – candidates or customers. Talent mining or lead generation.</li>
                                        <li>Lending credibility – allowing a company to use your name</li>
                                        <li>Conducting a training program for a specific technology</li>
                                        <li>Creating a technical assessment quiz</li>
                                        <li>Curating or creating interesting and educative content</li>
                                        <li>Book appreciation discussions specific to a technology</li>
                                        <li>Running boot camps/ dojos</li>
                                        <li>Organizing coding competitions</li>
                                    </ul>
                                </div>
                                <Typography className={classes.pageParaText3}>Bigger, more innovative, and interesting projects will need prior authorization from the employer</Typography>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <Footer />
        </div>
    )
}

LearningEarning.propType = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(LearningEarning);