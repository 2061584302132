import React, { Component } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Administration from "./modules/rezoomexPages/Administration";
import ApplicantTracking from "./modules/rezoomexPages/ApplicantTracking";
import HRSystemsIntegration from "./modules/rezoomexPages/HRSystemsIntegration";
import NFTMarketPlace from "./modules/rezoomexPages/NFTMarketPlace";
import Assesment from "./modules/rezoomexPages/Assesment";
import RecruiterPerformance from "./modules/rezoomexPages/RecruiterPerformance";
import Paywatch from "./modules/rezoomexPages/Paywatch";
import ReportsAndDashboards from "./modules/rezoomexPages/ReportsAndDashboards";
import NoMoreShopping from "./modules/rezoomexPages/NoMoreShopping";
import LearningEarning from "./modules/rezoomexPages/LearningEarning";
import RoboticEngagement from "./modules/rezoomexPages/RoboticEngagement";
import EarnHandsomeReturn from "./modules/rezoomexPages/EarnHandsomeReturn";
import RemoteWorkers from "./modules/rezoomexPages/RemoteWorkers";
import Moonlighting from "./modules/rezoomexPages/Moonlighting";
import WorkingRemotely from "./modules/rezoomexPages/WorkingRemotely";
import UserDetails from "./modules/rezoomexPages/UserDetails";
import VirtualOath from "./modules/rezoomexPages/VirtualOath";
import Oath from "./modules/rezoomexPages/Oath";
import SurveyReport from "./modules/rezoomexPages/SurveyReport";
import Accomplishments from "./modules/rezoomexPages/accomplishments";
import GigWork from "./modules/rezoomexPages/GigWork";
import Deterrent from "./modules/rezoomexPages/Deterrent";
import RemoteFirst from "./modules/rezoomexPages/RemoteFirst";
import CBDC from "./modules/rezoomexPages/CBDC";
import PressRelease from "./modules/rezoomexPages/PressRelease";
import ScrollToTop from "./components/ScrollToTop";
import SCDD from "./modules/rezoomexPages/SCDD";
export class Main extends Component {
  render() {
    return (
      <Router>
        <div>
          <ScrollToTop/>
          <Routes>
            <Route path="/" element={<NFTMarketPlace />} />
            <Route path="/assessment" element={<Assesment />} />
            <Route path="/performance" element={<RecruiterPerformance />} />
            <Route path="/paywatch" element={<Paywatch />} />
            <Route path="/deterrent" element={<Deterrent />} />
            <Route path="/remoteFirst" element={<RemoteFirst />} />
            <Route path="/adminstration" element={<Administration />} />
            <Route path="/smartContracts" element={<CBDC />} />
            <Route path="/scdd" element={<SCDD />} />
            <Route path="/reports" element={<ReportsAndDashboards />} />
            <Route path="/hrsystems" element={<HRSystemsIntegration />} />
            <Route path="/applicant" element={<ApplicantTracking />} />
            <Route path="/nomoreshopping" element={<NoMoreShopping />} />
            <Route path="/learningearning" element={<LearningEarning />} />
            <Route path="/roboticengagement" element={<RoboticEngagement />} />
            <Route path="/earnhandsomereturn" element={<EarnHandsomeReturn/>} />
            <Route path="/remoteworkers" element={<RemoteWorkers/>} />
            <Route path="/moonlighting" element={<Moonlighting />} />
            <Route path="/workingremotely" element={<WorkingRemotely />} />
            <Route path="/userdetails" element={<UserDetails />} />
            <Route path="/virtualoath" element={<VirtualOath />} />
            <Route path="/oath" element={<Oath />} />
            <Route path="/surveyreport" element={<SurveyReport />} />
            <Route path="/gigwork" element={<GigWork />} />
            <Route path="/accomplishments" element={<Accomplishments />} />
            <Route path="/pressrelease" element={<PressRelease />} />
          </Routes>
        </div>
      </Router>
    );
  }
}

export default Main;
